<!--大厦设施-->
<template>
  <div class="giEdificeDiv">
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col style="background: #fff; margin-bottom: 30px">
        <div class="partDiv" v-for="(item, index) in echoList" :key="index">
          <div class="partTitleFont">{{item.facilityType}}</div>
          <top-tab-switch :tab-list="item.tabList"
                          @tabActive="tabActive"
                          :other-data="{pIndex: index}"
                          class="tabClass" />
          <div class="flexJustifyContentCenter">
            <el-carousel class="carouselClass" arrow="never" >
              <el-carousel-item v-for="( it, i ) in item.imgList[item.tabIndex]" :key="i" class="flexACenterJCenter">
                <img :src="it.picUrl" alt="" style="width: 100%; height: 100%;">
                <div class="downShadowDiv" v-if="item.facilityType === '共享空间'">
                  <div class="downShadowText">
                    <span class="downSpanClass">{{it.describe}}</span>
                  </div>
                </div>
                <!--需求变动，增加描述-->
                <div class="downShadowDiv" v-if="item.facilityType === '场馆图参'">
                  <div class="downShadowText">
                    <span class="downSpanClass">{{it.depict}}</span>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topTabSwitch from '@/components/topTabSwitch'
import topPathList from '@/components/topPathList'
import axios from 'axios'
import { echoImg } from '@/utils/commonApi'

export default {
  name: 'facilities',
  components: {
    topTabSwitch,topPathList
  },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '大厦设施',
          path: '/giEdifice/facilities'
        },
      ],
      topTabIndex: 0,
      centerTabIndex: 0,
      downTabIndex: 0,
      echoList: [],
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    getData() {
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/edifice/facilitie/list",
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.handleData(res.data.data)
        } else {
          this.$message.error('查询失败')
        }
      }).catch(err => {
        this.$message.error('接口调用异常')
      })
    },
    handleData(data) {
      let typeList = data.map(item => item.facilityType);
      // 去重
      let typeArr = [...new Set(typeList)];
      let obj = []
      typeArr.forEach(item => {
        obj.push({
          facilityType: item,
          tabList: [],
          imgList: [],
          tabIndex: 0,
        })
      })
      obj.forEach(item => {
        data.forEach(it => {
          if (it.facilityType == item.facilityType) {
            item.tabList.push({ name: it.facilityName });
            if (it.details && it.details.length > 0) {
              let arr = []
              it.details.forEach(i => {
                arr.push({
                  picId: i.picture,
                  picUrl: i.pictureUrl,
                  describe: i.facilityDescribe,
                  depict: it.facilityDepict
                })
              })
              item.imgList.push(arr)
            }
          }
        })
      })
      this.echoList = obj;
    },
    tabActive({ tab, index, otherData }) {
      let i = otherData.pIndex;
      this.$set(this.echoList[i], 'tabIndex', index)
    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.giEdificeDiv {
  background-color: #f8f9fa !important;
}
.partDiv {
  margin: 30px 0;
}
.partTitleFont {
  font-size: 20px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
  text-align: center;
}
.spaceFont {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 24px;

  .specificFont {
    font-size: 24px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    line-height: 28px;
  }
  .areaColor {
    color: #136FFF;
  }
  .moneyColor {
    color: #00B894;
  }
  .peopleColor {
    color: #FC9B00;
  }
}
.detailDiv {
  display: flex;
  align-items: center;
  margin: 10px 30px;

  .iconClass {
    width: 30px;
    height: 30px;
  }
}
.reservationDiv {
  width: 78px;
  height: 78px;
  background: rgba(24,108,245,0.63);
  border-radius: 8px;
  position: relative;
  bottom: 195px;
  left: 1356px;

  .reservationFont {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
  }
}
.tabClass {
  margin: 15px 0;
}
.carouselClass {
  position: relative;
  top: -13%;
  width: 95%;

  ::v-deep .el-carousel__container {
    height: 36rem;
  }

  ::v-deep .el-carousel__button {
    width: 13px;
    height: 13px;
    border-radius: 7px;
  }
  ::v-deep .el-carousel__indicators--horizontal {
    right: 20px!important;
    bottom: 10px;
    left: auto;
    transform: translateX(-30%);
  }
}
.downShadowDiv {
  width: 100%;
  height: 36px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  bottom: 0;
  left: 0px;
  text-align: left;
  padding-left: 15px;
  padding-top: 16px;

  .downShadowText {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .downSpanClass {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #FFFFFF;
    }
  }
}
</style>
