<template>
  <div>
      <div class="rowFlexCenter">
        <div v-for="(item, index) in tabList" :key="index" @click="clickTab(item, index)" style="margin: 10px"
             class="cursorPointer" :class="tabIndex === index ? 'active' : 'unActive'" >
          <span class="tabFontClass">{{item.name}}</span>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'topTabSwitch',
  props: {
    tabList: {
      type: Array,
      immediate: true,
      default: () => {
        return []
      }
    },
    otherData: {
      type: Object,
      immediate: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  methods: {
    clickTab(tab, index) {
      this.tabIndex = index;
      const otherData = this.otherData;
      this.$emit('tabActive', { tab, index, otherData })
    },
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.active {
  min-width: 140px;
  height: 46px;
  background-image: url("~@/assets/img/giEdifice/activeBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  text-align: center;

  .tabFontClass {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 46px;
    margin: 0 15px;
  }
}
.unActive {
  min-width: 138px;
  height: 46px;
  background: #E8F1FB;
  border-radius: 5px;
  border: 1px solid #186CF5;
  text-align: center;

  .tabFontClass {
    font-size: 18px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #186CF5;
    line-height: 46px;
    margin: 0 15px;
  }
}
</style>
